import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: ${theme.space[10]}px auto;
  padding: ${theme.space[5]}px;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  color: #686868;
  display: flex;
  flex-direction: column;
`

Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[8]}px 0;
  color: #000000;
`

Policy.titleUpperBorder = styled.span`
  font-size: ${theme.font['3xl']};
  border-top: 1px solid #e9e9e9;
  text-transform: capitalize;
  margin-top: ${theme.space[24]}px;
  padding-bottom: ${theme.space[5]}px;
`

Policy.subtitle = styled.span`
  padding: ${theme.space[10]}px 0 ${theme.space[3]}px 0;
  font-size: ${theme.font['xl']};
  text-transform: uppercase;
`

Policy.listSubtitle = styled.span`
  font-size: ${theme.font['xl']};
  padding: ${theme.space[8]}px 0;
  text-transform: capitalize;
`
Policy.list = styled.ol`
  list-style-type: ${(props) =>
    props.isRomanNumerals ? 'lower-roman' : 'upper-alpha'};
  padding-left: ${theme.space[8]}px;
`

Policy.listChild = styled.li`
  font-size: ${theme.font.base};
  padding-bottom: ${theme.space[5]}px;
`

Policy.paragraph = styled.span`
  margin-top: ${theme.space[2]}px;
  font-size: ${theme.font.base};
  padding-bottom: ${theme.space[3]}px;
`

Policy.quickLinkContainer = styled.div`
  padding-top: ${theme.space[6]}px;
  display: flex;
  flex-direction: column;
`

Policy.quickLink = styled(Link)`
  color: ${theme.colors.font.green};
  font-size: ${theme.font.base};
  text-decoration: none;
  display: contents;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
  text-transform: capitalize;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.font.green};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
  :before {
    content: ' ';
  }
`

Policy.updatedText = styled.span`
  margin-top: ${theme.space[4]}px;
  font-size: ${theme.font['sm']};
`

export default Policy
